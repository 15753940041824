<template>

 <b-tabs align="center" class="tab-primary">
     <b-tab v-if="deliveryPruebaExist" class="flex flex-centered">
      <template #title>
        <div class="tab-title">
          <span>Prueba entrega</span>
        </div>
      </template>

        <div class="Undefault">
          <div class="image-scroll-container">
            <b-img
              bottom
              :src="imgError"
              :alt="'Error'"
            ></b-img>
          </div>

          <div class="delivery-status">
            <p v-show="!loadingActualizacion" class="message-undefault">No hemos encontrado la prueba de entrega proporcionada por el Courier. Por favor, presiona el botón "Actualizar" para verificar si está disponible.
            </p>
            <span v-show="loadingActualizacion" class="loading-indicator">...</span>
          </div>


          <b-button variant="info" class="" @click="validate()">
            <b-spinner v-show="loadingActualizacion"  />
            <span v-if="!loadingActualizacion"> Actualizar</span>
          </b-button>
        </div>



    </b-tab>

    <b-tab v-show="!deliveryPruebaExist" v-for="(item, index) in carouselData" :key="index">
      <template #title>
        <div class="tab-title">
          <span>{{ item.label }}</span>
        </div>
      </template>
      <div class="image-container">
        <div v-if="!isPdf(item.carier_url)" class="image-scroll-container">
          <b-img bottom :src="item.carier_url" class="img-fluid" :alt="item.label"
          :style="{'transform': `rotate(${control.rotation}deg) scale(${control.zoom || 1})`}"
          ></b-img>
          <div class="icon-container">
            <feather-icon icon="RotateCwIcon" v-on:click="rotateImage(control)" class="icon"/>
            <feather-icon icon="ZoomInIcon" class="icon" @click="zoomInImage(control)" />
            <feather-icon icon="ZoomOutIcon" class="icon" @click="zoomOutImage(control)" />
            <feather-icon icon="ExternalLinkIcon" class="icon" @click="openImageInNewWindow(item.carier_url)" />
          </div>
        </div>
        <div v-if="isPdf(item.carier_url)">
          <iframe :src="item.carier_url" style="height:100vh;width:100%"/>
          <div class="icon-container">
            <feather-icon icon="ExternalLinkIcon" class="icon" @click="openImageInNewWindow(item.carier_url)" />
          </div>
        </div>
      </div>
    </b-tab>



  </b-tabs>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      carouselData: [],
      control: {
        rotation: 0,
        fullscreen: false,
        zoom: 1
      },
      imgError: require('@/assets/images/ImgPrueba.png'),
      deliveryPruebaExist: null,
      loadingActualizacion: false
    }
  },
  computed: {
    ...mapGetters({
      deliveryPrueba: 'getDeliveyPrueba',
      deliveryPruebaError: 'getDeliveryPruebaError',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    deliveryPrueba() {
      this.carouselData = this.deliveryPrueba.delivery_proof.map(slide => ({
        ...slide,
        label: `Prueba de ${slide.label}`
      }))
    },
    deliveryPruebaError() {
      this.deliveryPruebaExist = this.deliveryPruebaError
    },
    generalLoading() {
      this.loadingActualizacion = this.generalLoading.getDeliveyPrueba
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData(forceTracking = false) {
      const params = {
        delivery_id : this.$route.params.delivery_id
      }
      this.$store.dispatch('fetchDeliveryPrueba', { params, forceTracking })
    },
    zoomInImage(item) {
      item.zoom = (item.zoom || 1) * 1.1
    },
    zoomOutImage(item) {
      item.zoom = (item.zoom || 1) / 1.1
    },
    openImageInNewWindow(url) {
      window.open(url, '_blank')
    },
    rotateImage(item) {
      item.rotation = (item.rotation + 90) % 360
    },
    validate() {
      this.loadingActualizacion = true
      this.setInitialData(true)
    },
    isPdf(item) {
      console.log('match', item.match(/\.pdf/))
      return item.match(/\.pdf/)
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .card-img-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .carousel-caption{
    width: auto;
    background-color: #000;
    color: #fff !important;
    opacity: .8;
    border-radius: 15px;
  }
  .carousel-caption h3{
    color: #fff !important;
    font-size: 1.2rem;
  }
  .img-fluid {
    height : 432px !important;
    width: 100%;
    object-fit: contain;
  }
  .icon-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    background: #0000002e;
    border-radius: 0.5rem;
    padding-right: 0.5rem;
  }

  .icon {
    cursor: pointer;
    margin-left: 0.5rem;
    height: 2rem;
    width: 2rem;
    color: #fff;
  }
  .image-container {
  position: relative;
  }

  .image-scroll-container {
    overflow: auto;
    max-height: 632px;
    max-width: 100%;
  }

  .Undefault{
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    max-height: 900px;
  }

.delivery-status {
  text-align: center;
  margin: 20px;
}

.message-undefault {
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.loading-indicator {
  font-size: 24px;
  color: #007bff;
}
</style>
